import { Card, CompanyAvatar, Tag, TruncatedText, XMoreWrapper } from "@components/library";
import { COLORS, FEATURE_FLAGS, FONTS } from "@constants";
import useFollowRequest from "@hooks/useFollowRequest";
import { RootState } from "@redux/store";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { ConfidentialCompany, RequestForCard } from "@tsTypes/index";
import { LaunchableRequestStatus } from "@tsTypes/requests";
import { UserRole } from "@tsTypes/users";
import { track } from "@utils/appUtils";
import { getLaunchableRequestStatus, requestPageUrlPath } from "@utils/requestUtils";
import { checkRfpCompanyHidden } from "@utils/rfpUtils";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import RefineRecommendations from "./RefineRecommendations";
import RfpCardFooter from "./RfpCardFooter";

interface Props {
  request: RequestForCard;
  onClick?: (...args: any[]) => any;
  shouldShowUnsubscribeButton?: boolean;
  fromHref?: string;
}

const RfpCard = ({ request, onClick, shouldShowUnsubscribeButton = false, fromHref }: Props) => {
  const [isCardHovered, setIsCardHovered] = useState(false);
  const [shouldFade, setShouldFade] = useState(false);

  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);

  const { title, company, disciplines, areas_of_expertise } = request;

  const { handleFollowRequest, isUserFollowing } = useFollowRequest(request.id);

  const handleNotify = () => {
    if (getLaunchableRequestStatus(request) !== LaunchableRequestStatus.COMING_SOON) return;

    handleFollowRequest();

    track(SegmentEventName.Click, {
      ui_component: "Coming soon request card",
      react_component: "RfpCardNew",
      logged_in: false,
    });
  };

  let keywords: string[];
  if (!currentUser?.id) {
    keywords = [...(disciplines ?? []), ...(areas_of_expertise ?? [])];
  } else if (currentUser.role === UserRole.UNIVERSITY_ADMIN) {
    keywords = disciplines;
  } else {
    keywords = areas_of_expertise;
  }
  // Remove duplicates just in case
  keywords = Array.from(new Set(keywords));

  const isComingSoon = getLaunchableRequestStatus(request) === LaunchableRequestStatus.COMING_SOON;
  const isPrivate = checkRfpCompanyHidden(request, currentUser);

  const CardLink = isComingSoon || shouldShowUnsubscribeButton ? DisabledCardLink : ActiveCardLink;

  return (
    <FadeWrapper shouldFade={shouldFade}>
      <CardLink
        to={{
          pathname: requestPageUrlPath(request),
          fromHref,
        }}
      >
        <Card
          padding="0px"
          showHoverBorder
          onClick={onClick ?? handleNotify}
          onMouseEnter={() => setIsCardHovered(true)}
          onMouseLeave={() => setIsCardHovered(false)}
          data-testid="rfp-card"
        >
          <MainContent>
            <Company data-testid="company-name">
              <CompanyAvatar
                isPrivate={isPrivate}
                imageUrl={"avatar" in company ? company.avatar.url : null}
                size="sm"
              />
              {isPrivate ? (
                (company as ConfidentialCompany).private_alias ?? "Private"
              ) : (
                <TruncatedText
                  text={"company_name" in company ? company.company_name : ""}
                  font={FONTS.SEMIBOLD_1}
                  textWidth="calc(100% - 48px)"
                  tooltipWidth="200px"
                />
              )}
            </Company>
            <Title data-testid="title">
              <TruncatedText text={title} lines={4} font={FONTS.MEDIUM_1} />
            </Title>
            <KeywordContainer data-testid="keywords">
              <XMoreWrapper maxLines={2}>
                {keywords.map((keyword) => (
                  <Tag key={keyword} content={keyword} size="sm" />
                ))}
              </XMoreWrapper>
            </KeywordContainer>
            {FEATURE_FLAGS.RELEVANT_NOT_RELEVANT_BUTTONS && (
              <RefineRecommendations
                request={request}
                handleReveal={() => setShouldFade(false)}
                handleFade={() => setShouldFade(true)}
              />
            )}
          </MainContent>
          <RfpCardFooter
            request={request}
            isUserFollowing={isUserFollowing ?? false}
            isCardHovered={isCardHovered}
            shouldShowUnsubscribeButton={shouldShowUnsubscribeButton}
          />
        </Card>
      </CardLink>
    </FadeWrapper>
  );
};

export default RfpCard;

const FadeWrapper = styled.div<{ shouldFade: boolean }>`
  opacity: ${({ shouldFade }) => (shouldFade ? 0.4 : 1)};
  transition: opacity 0.3s ease-out;
`;

const ActiveCardLink = styled(Link)`
  display: block;
  :visited,
  :link {
    color: ${COLORS.BLACK};
    text-decoration: none;
  }
`;
const DisabledCardLink = styled.div`
  display: block;
  color: ${COLORS.BLACK};
  text-decoration: none;
`;
const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 305px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 24px 24px 16px;
  border-radius: ${({ borderRadius }) => borderRadius};
`;
const Company = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 32px;
  ${FONTS.SEMIBOLD_1};
`;
const Title = styled.div`
  height: 54px;
  margin-bottom: auto;
  ${FONTS.MEDIUM_1};
`;
const KeywordContainer = styled.div`
  margin-bottom: 16px;
`;
