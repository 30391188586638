import Footer from "@components/Footer";
import NavBar from "@components/Navbar";
import { setShowErrorBoundary } from "@redux/actions/defaultActions";
import { t, t_html } from "@utils/i18n";
import { ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import NotFound404 from "../StatusPages";
import Unauthorized401 from "../StatusPages/401";
import Forbidden403 from "../StatusPages/403";
import StatusPage from "../StatusPages/StatusPage";

const ErrorBoundaryFallback = ({ error }: { error: any }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setShowErrorBoundary(true));
  }, []);

  // NOTE: (justinpchang) If ChunkLoadError, attempt to refresh the page once to refresh JS cache.
  if (error.name === "ChunkLoadError") {
    if (!window.location.hash.endsWith("refresh")) {
      window.location.replace(window.location + "#refresh");
      window.location.reload();
    }
  }

  let innerComponent: ReactNode | null = null;
  if (error.isAxiosError) {
    const status = error.response.status;
    if (status === 401) {
      innerComponent = <Unauthorized401 />;
    } else if (status === 404) {
      innerComponent = <NotFound404 />;
    } else if (status === 403) {
      innerComponent = <Forbidden403 />;
    }
  } else {
    innerComponent = (
      <StatusPage
        quote={t_html("pages.error_pages.fallback.quote_html")}
        author={t("pages.error_pages.fallback.author")}
        errorMessage={t_html("pages.error_pages.fallback.error_message_html")}
        isErrorBoundary
      />
    );
  }

  return (
    <Container>
      <NavBar shouldIgnoreHiddenPaths />
      {innerComponent}
      <Footer />
    </Container>
  );
};

export default ErrorBoundaryFallback;

const Container = styled.div`
  height: 100%;
`;
