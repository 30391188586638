import { RequestableType } from "@tsTypes/requests";
import { lazyWithRetry } from "@utils/appUtils";
import { Route } from "react-router-dom";
import { request_for_expert_path } from "./routes.js";
import { formatPathForRouter } from "./utils";

const RfePage = lazyWithRetry(() => import("../views/RequestForExpertsPage"));
const ProposalPage = lazyWithRetry(() => import("../views/ProposalPage"));

const routes = [
  <Route
    exact
    key="rfe_routes/1"
    path={formatPathForRouter(request_for_expert_path)}
    component={RfePage}
  />,
  <Route exact key="rfe_routes/2" path={"/request_for_expert_proposals/:slug"}>
    <ProposalPage requestableType={RequestableType.REQUEST_FOR_EXPERT} />
  </Route>,
];

export default routes;
