import { Request, UserRequestPreference } from "@tsTypes/schema.js";
import * as Routes from "../routes/routes.js";
import { UserRequestPreferenceSource } from "../tsTypes/__generated__/enums/user_request_preference_source";
import api from "./request";

type UserRequestPreferenceResponse = Pick<UserRequestPreference, "id" | "is_interested"> & {
  request?: Pick<Request, "id" | "title">;
};

export const getUserRequestPreference = async (
  requestId: number
): Promise<UserRequestPreferenceResponse | null> => {
  try {
    const response = await api.get(`/requests/${requestId}/user_request_preference`);
    return response.data;
  } catch (error) {
    // Return null if the preference doesn't exist or there's an error
    return null;
  }
};

export const deleteUserRequestPreference = async (requestId: number) => {
  await api.delete(`/requests/${requestId}/user_request_preference`);
};

export const updateUserRequestPreference = async ({
  requestId,
  signedNotificationId,
  isInterested = true,
  source = UserRequestPreferenceSource.Unknown,
  reason,
}: {
  requestId: number;
  isInterested?: boolean;
  source?: UserRequestPreferenceSource;
  reason?: string;
  signedNotificationId?: string;
}) => {
  const payload: Record<string, any> = {
    is_interested: isInterested,
    source: source,
  };

  // Only include if provided
  if (signedNotificationId) payload.signed_notification_id = signedNotificationId;
  if (reason) payload.reason = reason;

  const responseJson = await api.post(
    Routes.update_request_interests_path({
      id: requestId,
    }),
    payload
  );

  return responseJson.data;
};
