import { t, t_html } from "@utils/i18n";
import StatusPage from "./StatusPage";

const Unauthorized401 = () => {
  return (
    <StatusPage
      quote={t("pages.error_pages.401.quote")}
      author={t("pages.error_pages.401.author")}
      errorMessage={t_html("pages.error_pages.401.error_message_html")}
    />
  );
};

export default Unauthorized401;
