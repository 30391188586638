import { useEffect, useState } from "react";

export default function useIsImageLoadable(imageUrl: string | null | undefined) {
  const [isLoadable, setIsLoadable] = useState(false);

  useEffect(() => {
    if (imageUrl) {
      const img = new Image();
      img.onload = () => setIsLoadable(true);
      img.onerror = () => setIsLoadable(false);
      img.src = imageUrl;
    }
  }, [imageUrl]);

  return isLoadable;
}
