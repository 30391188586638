import { google_scholar_authors_path } from "@routes/routes";
import { GoogleScholarAuthor } from "@tsTypes/google_scholar_authors";
import request from "./request";

export const getGoogleScholarAuthors = async (): Promise<GoogleScholarAuthor[]> => {
  const response = await request.get(google_scholar_authors_path());
  return response.data?.authors || [];
};

export const getGoogleScholarAuthor = async (
  authorId: string
): Promise<{
  user_id: number;
}> => {
  const response = await request.post(
    google_scholar_authors_path(),
    { author_id: authorId },
    { timeout: 10000 }
  );
  return response.data;
};
