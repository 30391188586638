import { CompanyMember } from "@tsTypes/index";
import { ShowSubscription } from "@tsTypes/subscriptions/show";
import { Reducer } from "redux";
import {
  LOADING_MESSAGES,
  LOAD_COMPANY_MEMBERS,
  LOAD_PROPOSALS,
  LOAD_SUBSCRIPTION,
  LOAD_USER_FOLLOW,
  SET_COMPANY_MEMBERS,
  SET_MESSAGES,
  SET_PROPOSAL_AS_SEEN,
  SET_PROPOSAL_DASHBOARD_RFP,
  SET_SUBSCRIPTION,
  SET_USER_FOLLOW,
  SET_VIEW_AS_COMPANY_SUPER_ADMIN,
} from "../actions/companyActions";

interface CompanyState {
  isLoadingSubscription: boolean;
  subscription: ShowSubscription | null;
  loadingProposals: boolean;
  proposals: any[];
  proposalDashboardRequest: any;
  userFollow: boolean;
  loadingUserFollow: boolean;
  researchAreaHeadline: string;
  researchAreaImageUrl: string;
  loadingCompanyMembers: boolean;
  companyMembers: CompanyMember[];
  loadingMessages: boolean;
  messages: any[];
  viewAsCompanySuperAdmin: boolean;
}

const initialState: CompanyState = {
  isLoadingSubscription: false,
  subscription: null,
  loadingProposals: false,
  proposals: [],
  proposalDashboardRequest: {},
  userFollow: false,
  loadingUserFollow: false,
  researchAreaHeadline: "",
  researchAreaImageUrl: "",
  loadingCompanyMembers: false,
  companyMembers: [],
  loadingMessages: false,
  messages: [],
  viewAsCompanySuperAdmin: false,
};

const companyReducer: Reducer<CompanyState> = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SUBSCRIPTION:
      return {
        ...state,
        isLoadingSubscription: true,
      };
    case SET_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload,
        isLoadingSubscription: false,
      };
    case LOAD_USER_FOLLOW:
      return {
        ...state,
        loadingUserFollow: true,
      };
    case SET_USER_FOLLOW:
      return {
        ...state,
        userFollow: action.payload,
        loadingUserFollow: false,
      };
    case LOAD_PROPOSALS:
      return {
        ...state,
        loadingProposals: true,
      };
    case SET_PROPOSAL_DASHBOARD_RFP:
      return {
        ...state,
        proposalDashboardRequest: action.payload,
      };
    case LOAD_COMPANY_MEMBERS:
      return {
        ...state,
        loadingCompanyMembers: true,
      };
    case SET_COMPANY_MEMBERS:
      return {
        ...state,
        companyMembers: action.payload,
        loadingCompanyMembers: false,
      };
    case LOADING_MESSAGES:
      return {
        ...state,
        loadingMessages: true,
      };
    case SET_MESSAGES:
      return {
        ...state,
        loadingMessages: false,
        messages: action.payload,
      };
    case SET_VIEW_AS_COMPANY_SUPER_ADMIN:
      return {
        ...state,
        viewAsCompanySuperAdmin: action.payload,
      };
    case SET_PROPOSAL_AS_SEEN:
      return {
        ...state,
        proposals: state.proposals.map((p) => {
          if (p.id == action.payload.proposalId) {
            p.seen = true;

            const seen_by = p?.seen_by || [];

            const fullName =
              action.payload.currentUser?.first_name + " " + action.payload.currentUser?.last_name;

            if (seen_by.filter((name) => name.join(" ") === fullName).length === 0) {
              p?.seen_by.push([
                action.payload.currentUser?.first_name,
                action.payload.currentUser?.last_name,
              ]);
            }

            return p;
          }
          return p;
        }),
      };
    default:
      return state;
  }
};

export default companyReducer;
