import {
  ADD_FILTER,
  LOADING_CURRENT_COMPANY,
  LOAD_CURRENT_RFP,
  LOAD_DELETE_COMPANY_INTEREST_PARTNER,
  REMOVE_FILTER,
  SEARCH_TERM,
  SET_COMPANIES,
  SET_COMPANY_INFO,
  SET_COMPANY_INTEREST,
  SET_COMPANY_LOGO,
  SET_COMPANY_VIDEO,
  SET_CURRENT_COMPANY,
  SET_FOLLOWED_REQUESTS_INFO,
  SET_FOUNDATIONS,
  SET_GOVERNMENT_ORGANIZATIONS,
  SET_INVITED_USERS,
  SET_LAST_VIEWED_RFP_IDENTIFIER,
  SET_LOAD_COMPANY_INFO,
  SET_LOAD_COMPANY_INTEREST,
  SET_LOAD_COMPANY_PARTNER,
  SET_LOAD_INVITED_USERS,
  SET_UNIVERSITY_TEAM_MEMBERS,
  SHOW_ERROR_BOUNDARY,
  UPDATE_CURRENT_RFP,
  UPDATE_RESULT_LIST,
} from "../actions/defaultActions";

const initialState = {
  showErrorBoundary: false,
  filters: {},
  searchTerm: "",
  isUpdatingToggleRFP: {},
  isUpdatingFollowedCompanies: {},
  isUpdatingResearchFollows: false,
  followedRequestsInfo: [],
  followedCompanies: {},
  resultList: [],
  companies: [],
  foundations: [],
  government_organizations: [],
  currentCompany: {},
  currentRFP: {},
  loadingCurrentCompany: false,
  loadingCurrentRFP: false,
  loadingInvitedUsers: false,
  invitedUsers: [],
  universityTeamMembers: {},
  lastViewedRfpIdentifier: "",
};

const defaultReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_ERROR_BOUNDARY:
      return {
        ...state,
        showErrorBoundary: action.payload,
      };
    case ADD_FILTER:
      const newFilters = state.filters[action.payload.filterType] || [];
      newFilters.push(action.payload.filterValue);
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterType]: Array.from(new Set(newFilters)),
        },
      };
    case REMOVE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.filterType]: state.filters[action.payload.filterType].filter(
            (v) => v !== action.payload.filterValue
          ),
        },
      };
    case LOADING_CURRENT_COMPANY:
      return {
        ...state,
        loadingCurrentCompany: action.payload,
      };
    case SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload,
      };
    case SET_FOLLOWED_REQUESTS_INFO:
      return {
        ...state,
        followedRequestsInfo: action.payload,
      };
    case UPDATE_RESULT_LIST:
      return {
        ...state,
        resultList: action.payload,
      };
    case UPDATE_CURRENT_RFP:
      return {
        ...state,
        currentRFP: action.payload,
        loadingCurrentRFP: false,
      };
    case LOAD_CURRENT_RFP:
      return {
        ...state,
        loadingCurrentRFP: true,
      };
    case SET_CURRENT_COMPANY:
      return {
        ...state,
        currentCompany: action.payload,
      };
    case SET_COMPANIES:
      return {
        ...state,
        companies: action.payload,
      };
    case SET_FOUNDATIONS:
      return {
        ...state,
        foundations: action.payload,
      };
    case SET_GOVERNMENT_ORGANIZATIONS:
      return {
        ...state,
        government_organizations: action.payload,
      };
    case SET_LOAD_COMPANY_PARTNER:
      return {
        ...state,
        loadingCompanyPartner: action.payload,
      };
    case SET_LOAD_COMPANY_INTEREST:
      return {
        ...state,
        loadingCompanyInterest: action.payload,
      };
    case SET_COMPANY_INTEREST:
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          company_research_interests: action.payload,
        },
        loadingCompanyInterest: false,
      };
    case SET_LOAD_COMPANY_INFO:
      return {
        ...state,
        loadingCompanyInfo: action.payload,
      };
    case SET_COMPANY_INFO:
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          location: action.payload.location,
          employee_count: action.payload.employee_count,
          rd_spend_amount: action.payload.rd_spend_amount,
          company_type: action.payload.company_type,
          description: action.payload.description,
        },
        loadingCompanyInfo: false,
      };
    case LOAD_DELETE_COMPANY_INTEREST_PARTNER:
      return {
        ...state,
        loadingDeleteCompanyInterestPartner: action.payload,
      };
    case SET_INVITED_USERS:
      return {
        ...state,
        invitedUsers: action.payload.invited_users,
        loadingInvitedUsers: false,
      };
    case SET_LOAD_INVITED_USERS:
      return {
        ...state,
        loadingInvitedUsers: action.payload,
      };
    case SET_UNIVERSITY_TEAM_MEMBERS:
      return {
        ...state,
        universityTeamMembers: action.payload,
      };
    case SET_COMPANY_LOGO:
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          logo: {
            url: action.payload,
          },
        },
      };
    case SET_COMPANY_VIDEO:
      return {
        ...state,
        currentCompany: {
          ...state.currentCompany,
          video: {
            url: action.payload,
          },
        },
      };
    case SET_LAST_VIEWED_RFP_IDENTIFIER:
      return {
        ...state,
        lastViewedRfpIdentifier: action.payload,
      };
    default:
      return state;
  }
};
export default defaultReducer;
