import { Icon } from "@components/library";
import toast from "@components/Toast";
import { COLORS, STYLES } from "@constants";
import useLocalStorage from "@hooks/useLocalStorage";
import { RootState } from "@redux/store";
import {
  deleteUserRequestPreference,
  getUserRequestPreference,
  updateUserRequestPreference,
} from "@requests/user_request_preferences";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { UserRequestPreferenceSource } from "@tsTypes/__generated__/enums/user_request_preference_source";
import { RequestForCard } from "@tsTypes/index";
import appsignal from "@utils/appsignal";
import { track } from "@utils/appUtils";
import { t } from "@utils/i18n";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

interface RefineRecommendationsProps {
  request: RequestForCard;
  trackingProps?: Record<string, any>;
  handleFade: () => void;
  handleReveal: () => void;
}

function useRequestPreferences(request: RequestForCard, isLoggedIn: boolean) {
  const [localPreference, setLocalPreference] = useLocalStorage<boolean | null>(
    `request_preference_${request.id}`,
    null
  );

  const queryClient = useQueryClient();
  const queryKey = ["userRequestPreference", request.id, isLoggedIn, localPreference];

  const { data: isInterestedQuery } = useQuery({
    queryKey,
    queryFn: async () => {
      if (isLoggedIn) {
        const preference = await getUserRequestPreference(request.id);
        return preference?.is_interested ?? null;
      }
      return localPreference;
    },
    initialData: request.user_request_preference?.is_interested,
    // Prevent automatic refetching
    // This allows us to use the initial data from request.user_request_preference
    // and only update the data when mutations explicitly invalidate the query
    staleTime: Infinity,
  });

  const { mutate: updateInterest } = useMutation({
    mutationFn: async (isInterested: boolean) => {
      if (isLoggedIn)
        return updateUserRequestPreference({
          requestId: request.id,
          isInterested,
          source: UserRequestPreferenceSource.MarketplaceCard,
        });
      setLocalPreference(isInterested);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
    onError: (error: Error) => {
      appsignal.sendError(error, (span) => {
        span.setAction("useRequestPreferences#updateInterest");
        span.setTags({
          requestId: String(request.id),
        });
      });
    },
  });

  const { mutate: deleteInterest } = useMutation({
    mutationFn: async () => {
      if (isLoggedIn) return deleteUserRequestPreference(request.id);
      setLocalPreference(null);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
    onError: (error: Error) => {
      appsignal.sendError(error, (span) => {
        span.setAction("useRequestPreferences#deleteInterest");
        span.setTags({
          requestId: String(request.id),
        });
      });
    },
  });

  return {
    isInterested: isInterestedQuery,
    updateInterest,
    deleteInterest,
  };
}

const i18nPrefix = "components.refine_recommendations";

const RefineRecommendations: React.FC<RefineRecommendationsProps> = ({
  request,
  trackingProps = {},
  handleFade,
  handleReveal,
}) => {
  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);
  const isLoggedIn = Boolean(currentUser.id);

  const { isInterested, updateInterest, deleteInterest } = useRequestPreferences(
    request,
    isLoggedIn
  );

  const handleRelevantClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    track(SegmentEventName.Click, {
      react_component: "RefineRecommendations",
      ui_component: "Relevant button",
      request_slug: request.slug,
      is_logged_in: isLoggedIn,
      ...trackingProps,
    });

    updateInterest(true);

    toast.success(
      <div>
        {t([i18nPrefix, "toasts.relevant"])}{" "}
        <UndoButton onClick={deleteInterest}>{t([i18nPrefix, "buttons.undo"])}</UndoButton>
      </div>
    );
  };

  const handleNotRelevantClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    track(SegmentEventName.Click, {
      react_component: "RefineRecommendations",
      ui_component: "Not relevant button",
      request_slug: request.slug,
      is_logged_in: isLoggedIn,
      ...trackingProps,
    });

    updateInterest(false);

    toast.success(
      <div>
        {t([i18nPrefix, "toasts.not_relevant"])}{" "}
        <UndoButton onClick={deleteInterest}>{t([i18nPrefix, "buttons.undo"])}</UndoButton>
      </div>
    );
  };

  useEffect(() => {
    if (isInterested === false) handleFade();
    else handleReveal();
  }, [isInterested]);

  return (
    <ButtonContainer>
      <RelevantButton
        onClick={handleRelevantClick}
        $selected={isInterested}
        aria-label={t([i18nPrefix, "buttons.relevant.aria_label"])}
      >
        <Icon name="Checkmark" color={COLORS.BLUE_500} size="sm" />
      </RelevantButton>
      <NotRelevantButton
        onClick={handleNotRelevantClick}
        $selected={isInterested === false}
        aria-label={t([i18nPrefix, "buttons.not_relevant.aria_label"])}
      >
        <Icon name="Block" color={COLORS.NEUTRAL_500} size="sm" />
      </NotRelevantButton>
    </ButtonContainer>
  );
};

export default RefineRecommendations;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BaseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 34px;

  border: 1px solid ${COLORS.NEUTRAL_250};
  background-color: ${COLORS.WHITE};

  box-shadow: ${STYLES.SHADOW_CARD};
`;

const RelevantButton = styled(BaseButton)<{ $selected?: boolean }>`
  border-radius: 4px 0px 0px 4px;

  border: 1px solid ${COLORS.NEUTRAL_250};
  margin-right: 0;

  ${(props) =>
    props.$selected &&
    `
    background: ${COLORS.BLUE_LIGHT_300};
    border: 1px solid ${COLORS.BLUE_500};
  `}
`;

const NotRelevantButton = styled(BaseButton)<{ $selected?: boolean }>`
  border-radius: 0px 4px 4px 0px;

  border: 1px solid ${COLORS.NEUTRAL_250};
  border-left: none;
  margin-left: 0;

  ${(props) =>
    props.$selected &&
    `
    background: ${COLORS.NEUTRAL_100};
    border: 1px solid ${COLORS.NEUTRAL_500};
    margin-left: -1px;
  `}
`;
const UndoButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
`;
