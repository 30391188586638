import { t, t_html } from "@utils/i18n";
import StatusPage from "./StatusPage";

const NotFound404 = () => {
  return (
    <StatusPage
      quote={t("pages.error_pages.404.quote")}
      author={t("pages.error_pages.404.author")}
      errorMessage={t_html("pages.error_pages.404.error_message_html")}
    />
  );
};

export default NotFound404;
