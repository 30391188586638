import { t, t_html } from "@utils/i18n";
import StatusPage from "./StatusPage";

const Forbidden403 = () => {
  return (
    <StatusPage
      quote={t("pages.error_pages.403.quote")}
      author={t("pages.error_pages.403.author")}
      errorMessage={t_html("pages.error_pages.403.error_message_html")}
    />
  );
};

export default Forbidden403;
