import { BlankModal, Button, Icon } from "@components/library";
import { COLORS, FONTS } from "@constants";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { RequestUnion } from "@tsTypes/requests";
import { track } from "@utils/appUtils";
import { t } from "@utils/i18n";
import React, { useState } from "react";
import styled from "styled-components";

interface RelevanceFeedbackModalProps {
  isOpen: boolean;
  onClose: () => void;
  isRelevant?: boolean;
  request: RequestUnion;
  trackingProps?: Record<string, any>;
  onSubmit?: (option?: string) => void;
}

const i18nPrefix = "components.refine_recommendations";

const RelevanceFeedbackModal: React.FC<RelevanceFeedbackModalProps> = ({
  isOpen,
  onClose,
  isRelevant,
  request,
  trackingProps = {},
  onSubmit,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined);
  const [otherText, setOtherText] = useState<string>("");

  // Reset state and close modal
  const handleCloseModal = () => {
    setSelectedOption(undefined);
    setOtherText("");
    onClose();
  };

  const handleSubmit = () => {
    const feedbackText = selectedOption === "Other" ? `Other: ${otherText}` : selectedOption;

    track(SegmentEventName.Submit, {
      react_component: "RelevanceFeedbackModal",
      ui_component: isRelevant ? "Relevant modal" : "Not relevant modal",
      request_slug: request.slug,
      selected_option: feedbackText,
      ...trackingProps,
    });

    onSubmit?.(feedbackText);

    handleCloseModal();
  };

  const options = isRelevant
    ? t([i18nPrefix, "modal.options.relevant"])
    : t([i18nPrefix, "modal.options.not_relevant"]);

  const description = isRelevant
    ? t([i18nPrefix, "modal.description_relevant"])
    : t([i18nPrefix, "modal.description_not_relevant"]);

  const isOtherSelected = selectedOption === "Other";
  const isSubmitDisabled = !selectedOption || (isOtherSelected && !otherText.trim());

  return (
    <BlankModal isOpen={isOpen} onClose={handleCloseModal} width="400px">
      <ModalContent>
        {!isRelevant && (
          <ModalIcon>
            <Icon name="Checkmark" color={COLORS.NEUTRAL_700} size="lg" />
          </ModalIcon>
        )}

        <Header>{t([i18nPrefix, "modal.header"])}</Header>

        <SubHeader>{description}</SubHeader>
        <OptionsContainer>
          {(Array.isArray(options) ? options : []).map((option) => (
            <OptionButton
              key={option}
              isSelected={selectedOption === option}
              onClick={() => setSelectedOption(option)}
            >
              {option}
            </OptionButton>
          ))}

          {isOtherSelected && (
            <TextInputContainer>
              <TextInput
                placeholder={t([i18nPrefix, "modal.options.other"])}
                value={otherText}
                onChange={(e) => setOtherText(e.target.value)}
                autoFocus
              />
            </TextInputContainer>
          )}
        </OptionsContainer>

        <ModalActions>
          <Button variant="secondary" onClick={handleCloseModal} size="md">
            {t([i18nPrefix, "modal.skip"])}
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={isSubmitDisabled} size="md">
            {t([i18nPrefix, "modal.submit"])}
          </Button>
        </ModalActions>
      </ModalContent>
    </BlankModal>
  );
};

export default RelevanceFeedbackModal;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;
  width: 100%;
  text-align: center;
`;

const ModalIcon = styled.div`
  margin-bottom: 16px;
`;

const Header = styled.h3`
  ${FONTS.HEADING_5_REGULAR};
  margin: 0 0 16px 0;
  color: ${COLORS.NEUTRAL_800};
`;

const SubHeader = styled.p`
  ${FONTS.REGULAR_2};
  color: ${COLORS.NEUTRAL_500};
  margin: 0 0 24px 0;
  max-width: 400px;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  margin-bottom: 24px;
`;

const OptionButton = styled.button<{ isSelected: boolean }>`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${(props) => (props.isSelected ? COLORS.BLUE_500 : COLORS.NEUTRAL_250)};
  background-color: ${(props) => (props.isSelected ? COLORS.BLUE_LIGHT_50 : "white")};
  text-align: left;
  ${FONTS.REGULAR_2};
  color: ${COLORS.NEUTRAL_700};
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    border-color: ${COLORS.BLUE_450};
    background-color: ${(props) => (props.isSelected ? COLORS.BLUE_LIGHT_50 : COLORS.NEUTRAL_50)};
  }
`;

const TextInputContainer = styled.div`
  width: 100%;
  margin-top: -4px;
`;

const TextInput = styled.input`
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid ${COLORS.NEUTRAL_250};
  ${FONTS.REGULAR_2};
  color: ${COLORS.NEUTRAL_700};

  &:focus {
    outline: none;
    border-color: ${COLORS.BLUE_450};
  }
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
  width: 100%;
`;
