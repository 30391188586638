import appsignal from "@utils/appsignal";
import isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";

/**
 * Custom hook for managing data in localStorage
 * @param key The localStorage key to store the data under
 * @param initialValue The initial value to use if no value is found in localStorage
 * @returns A tuple containing the current value and a function to update it
 *
 * @example
 * // Store a simple string
 * const [name, setName] = useLocalStorage('userName', '');
 *
 * @example
 * // Store an object
 * const [settings, setSettings] = useLocalStorage('userSettings', {
 *   theme: 'light',
 *   notifications: true
 * });
 *
 * @example
 * // Update with callback function
 * const [counter, setCounter] = useLocalStorage('counter', 0);
 * setCounter(prev => prev + 1);
 */
export function useLocalStorage<T>(
  key: string,
  initialValue: T
): [T, (value: T | ((val: T) => T)) => void] {
  // State to store our value
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      appsignal.sendError(error, (span) => {
        span.setAction("useLocalStorage#initialize");
        span.setTags({
          key,
        });
      });
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that
  // persists the new value to localStorage.
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      appsignal.sendError(error, (span) => {
        span.setAction("useLocalStorage#setValue");
        span.setTags({
          key,
        });
      });
    }
  };

  // Update local storage if the key changes
  useEffect(() => {
    try {
      const item = window.localStorage.getItem(key);
      if (item) {
        const parsedItem = JSON.parse(item);
        if (!isEqual(parsedItem, storedValue)) {
          setStoredValue(parsedItem);
        }
      } else if (storedValue !== initialValue) {
        // If no item in storage, reset to initial value
        setStoredValue(initialValue);
      }
    } catch (error) {
      appsignal.sendError(error, (span) => {
        span.setAction("useLocalStorage#useEffect");
        span.setTags({
          key,
        });
      });
    }
  }, [key, initialValue]);

  // Add cross-tab synchronization
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === key) {
        setStoredValue(event.newValue ? JSON.parse(event.newValue) : initialValue);
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [key, initialValue]);

  return [storedValue, setValue];
}

export default useLocalStorage;
