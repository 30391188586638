import { Icon, Tooltip } from "@components/library";
import RelevanceFeedbackModal from "@components/modals/RelevanceFeedbackModal";
import { COLORS, FONTS, STYLES } from "@constants";
import useLocalStorage from "@hooks/useLocalStorage";
import { RootState } from "@redux/store";
import {
  getUserRequestPreference,
  updateUserRequestPreference,
} from "@requests/user_request_preferences";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { UserRequestPreferenceSource } from "@tsTypes/__generated__/enums/user_request_preference_source";
import { RequestUnion } from "@tsTypes/requests";
import appsignal from "@utils/appsignal";
import { track } from "@utils/appUtils";
import { openLoginModalWithRedirect } from "@utils/authUtils";
import { t } from "@utils/i18n";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { TOOLTIP_CONTENT } from "./tooltipContent";

interface RefineRecommendationsProps {
  request: RequestUnion;
  isComplete: boolean;
  trackingProps?: Record<string, any>;
}

function useRequestPreferences(requestId: number, isLoggedIn: boolean) {
  const [localPreference, setLocalPreference] = useLocalStorage<boolean | null>(
    `request_preference_${requestId}`,
    null
  );

  const queryClient = useQueryClient();
  const queryKey = ["userRequestPreference", requestId, isLoggedIn, localPreference];

  const { data: isInterestedQuery } = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      if (isLoggedIn) {
        const preference = await getUserRequestPreference(requestId);
        return preference?.is_interested ?? null;
      }
      return localPreference;
    },
  });

  const { mutate: updateInterest } = useMutation({
    mutationFn: async ({ isInterested, reason }: { isInterested: boolean; reason?: string }) => {
      if (isLoggedIn) {
        return updateUserRequestPreference({
          requestId,
          isInterested,
          source: UserRequestPreferenceSource.RequestPage,
          reason,
        });
      }
      setLocalPreference(isInterested);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
    onError: (error: Error) => {
      appsignal.sendError(error, (span) => {
        span.setAction("RefineRecommendations#updatePreference");
        span.setTags({
          requestId: String(requestId),
        });
      });
    },
  });

  return {
    isInterested: isInterestedQuery,
    updateInterest,
  };
}

const i18nPrefix = "components.refine_recommendations";

const RefineRecommendations: React.FC<RefineRecommendationsProps> = ({
  request,
  trackingProps = {},
  isComplete,
}) => {
  const [isRelevant, setIsRelevant] = useState<boolean | undefined>(undefined);
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);
  const isLoggedIn = Boolean(currentUser.id);

  const { isInterested, updateInterest } = useRequestPreferences(request.id, isLoggedIn);

  const handleRelevantClick = () => {
    if (isInterested) return;

    track(SegmentEventName.Click, {
      react_component: "RefineRecommendations",
      ui_component: "Relevant button",
      request_slug: request.slug,
      is_logged_in: isLoggedIn,
      ...trackingProps,
    });

    if (!isComplete) {
      setIsRelevant(true);
      setShowModal(true);
    } else {
      updateInterest({ isInterested: true });
    }
  };

  const handleNotRelevantClick = () => {
    if (isInterested === false) return;

    track(SegmentEventName.Click, {
      react_component: "RefineRecommendations",
      ui_component: "Not relevant button",
      request_slug: request.slug,
      is_logged_in: isLoggedIn,
      ...trackingProps,
    });

    setIsRelevant(false);
    setShowModal(true);
  };

  const handleFeedbackSubmit = (reason?: string) => {
    if (isRelevant === undefined) return;

    track(SegmentEventName.Submit, {
      react_component: "RefineRecommendations",
      ui_component: isRelevant ? "Relevant feedback" : "Not relevant feedback",
      request_slug: request.slug,
      selected_option: reason,
      is_logged_in: isLoggedIn,
      ...trackingProps,
    });

    updateInterest({ isInterested: isRelevant, reason });
    setShowModal(false);

    if (!isLoggedIn) {
      dispatch(openLoginModalWithRedirect());
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    if (!isLoggedIn) {
      dispatch(openLoginModalWithRedirect());
    }
  };

  return (
    <>
      <Container>
        <SectionHeading>
          <Icon name="Magic" color={COLORS.NEUTRAL_700} size="sm" />
          <HeadingText>{t([i18nPrefix, "header"])}</HeadingText>
        </SectionHeading>
        <QuestionText>{t([i18nPrefix, "question"])}</QuestionText>
        <ButtonContainer>
          <Tooltip
            position="left"
            tooltipWidth="275px"
            contentWidth="100%"
            arrowOffsetTop={"36px"}
            content={TOOLTIP_CONTENT.RELEVANT.NOT_SIGNED_IN}
            // Uncomment this when Saved Requests page is built:
            // content={TOOLTIP_CONTENT.RELEVANT[isLoggedIn ? "SIGNED_IN" : "NOT_SIGNED_IN"]}
            // arrowOffsetTop={isLoggedIn ? "56px" : "36px"}
          >
            <RelevantButton
              onClick={handleRelevantClick}
              $selected={isInterested}
              aria-label={t([i18nPrefix, "buttons.relevant.aria_label"])}
            >
              <Icon name="Checkmark" color={COLORS.BLUE_500} size="sm" />
              {t([i18nPrefix, "buttons.relevant.text"])}
            </RelevantButton>
          </Tooltip>
          <Tooltip
            position="left"
            tooltipWidth="275px"
            arrowOffsetTop="45px"
            contentWidth="100%"
            content={TOOLTIP_CONTENT.NOT_RELEVANT}
          >
            <NotRelevantButton
              onClick={handleNotRelevantClick}
              $selected={isInterested === false}
              aria-label={t([i18nPrefix, "buttons.not_relevant.aria_label"])}
            >
              <Icon name="Block" color={COLORS.NEUTRAL_500} size="sm" />
              {t([i18nPrefix, "buttons.not_relevant.text"])}
            </NotRelevantButton>
          </Tooltip>
        </ButtonContainer>
      </Container>

      <RelevanceFeedbackModal
        request={request}
        isOpen={showModal}
        isRelevant={isRelevant}
        trackingProps={trackingProps}
        onClose={handleModalClose}
        onSubmit={handleFeedbackSubmit}
      />
    </>
  );
};

export default RefineRecommendations;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  width: 100%;
`;

const SectionHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

const HeadingText = styled.div`
  ${FONTS.SEMIBOLD_1};
  color: ${COLORS.NEUTRAL_800};
`;

const QuestionText = styled.div`
  ${FONTS.REGULAR_2};
  color: ${COLORS.NEUTRAL_500};
  margin-bottom: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
`;

const BaseButton = styled.button`
  display: flex;
  height: 43px;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;

  width: 100%;

  border: 1px solid ${COLORS.NEUTRAL_250};
  background-color: ${COLORS.WHITE};

  box-shadow: ${STYLES.SHADOW_CARD};

  color: ${COLORS.BLUE_500};

  ${FONTS.BUTTON_3}
`;

const RelevantButton = styled(BaseButton)<{ $selected?: boolean }>`
  color: ${COLORS.BLUE_500};
  background: ${COLORS.WHITE};

  border-radius: 4px 0px 0px 4px;

  box-shadow: 0px 1px 3px 0px rgba(4, 11, 19, 0.06);

  border: 1px solid ${COLORS.NEUTRAL_250};
  margin-right: 0;

  ${(props) =>
    props.$selected &&
    `
    background: ${COLORS.BLUE_LIGHT_300};
    border: 1px solid ${COLORS.BLUE_500};
    margin-right: -1px;
  `}
`;

const NotRelevantButton = styled(BaseButton)<{ $selected?: boolean }>`
  color: ${COLORS.NEUTRAL_500};
  background: ${COLORS.WHITE};

  border-radius: 0px 4px 4px 0px;

  box-shadow: 0px 1px 3px 0px rgba(4, 11, 19, 0.06);

  border: 1px solid ${COLORS.NEUTRAL_250};
  border-left: none;
  margin-left: 0;

  ${(props) =>
    props.$selected &&
    `
    background: ${COLORS.NEUTRAL_100};
    border: 1px solid ${COLORS.NEUTRAL_500};
    margin-left: -1px;
  `}
`;
