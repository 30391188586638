import RfpCard from "@components/RfpCard/RfpCard";
import UnsubscribedRfpCard from "@components/RfpCard/UnsubscribedRfpCard";
import { BlankModal, Switch } from "@components/library";
import { BlankModalProps } from "@components/library/ModalBases/BlankModal";
import { COLORS, FONTS, WINDOW_DIMENSIONS } from "@constants";
import { useSearchParams } from "@hooks/router";
import haloLogo from "@images/logos/halo_logo.svg";
import {
  fetchRequestForProposalsFromNotification,
  updateNotificationSetting,
} from "@requests/notifications";
import { updateUserRequestPreference } from "@requests/user_request_preferences";
import { account_settings_path } from "@routes/routes";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { UserRequestPreferenceSource } from "@tsTypes/__generated__/enums/user_request_preference_source";
import { NotificationSettingsPartial, RfpRequestForCard } from "@tsTypes/index";
import { track } from "@utils/appUtils";
import appsignal from "@utils/appsignal";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const RfpUnsubscribeModal = ({ onClose, isOpen }: BlankModalProps) => {
  const [searchParams] = useSearchParams();
  const history = useHistory();
  const unsubscribeParam = searchParams.get("unsubscribe") ?? "";
  // NOTE: (overload119) nid is signed, whereas _nid is not.
  // In the past we used the signed_id until we discovered bugs related to it in some small % of cases
  // so we added the _nid fallback.
  const notifIdentifierParam = searchParams.get("nid") ?? searchParams.get("_nid") ?? "";

  const [step, setStep] = useState<"opportunities" | "communication">(
    unsubscribeParam === "1" ? "opportunities" : "communication"
  );
  const [requestForProposals, setRequestForProposals] = useState([] as RfpRequestForCard[]);
  const [unsubscribedRequestIds, setUnsubscribedRequestIds] = useState<number[]>([]);
  const [userId, setUserId] = useState<number>();
  const [isShadowProfile, setIsShadowProfile] = useState<boolean>(false);
  const [settings, setSettings] = useState<NotificationSettingsPartial>([]);

  const handleToggle = async (key, enabled) => {
    try {
      if (!userId) {
        return;
      }

      await updateNotificationSetting(userId, key, enabled);

      setSettings((prevSettings) => {
        const newSettings = [...prevSettings];

        const settingIndex = newSettings.findIndex((setting) => setting.key === key);

        newSettings[settingIndex] = {
          ...newSettings[settingIndex],
          enabled,
        };

        return newSettings;
      });
    } catch (err) {
      appsignal.sendError(err, (span) => {
        span.setAction("RfpUnsubscribeModal#handleToggle");
        span.setTags({
          notifIdentifierParam,
          enabled,
          key,
        });
      });
    }
  };

  const toggleUnsubscribeFromRfp = async (
    e: MouseEvent,
    request: RfpRequestForCard,
    isInterested: boolean
  ) => {
    e.preventDefault();

    if (notifIdentifierParam) {
      await updateUserRequestPreference({
        requestId: request.id,
        isInterested,
        source: UserRequestPreferenceSource.PreferenceModal,
        signedNotificationId: notifIdentifierParam,
      });
    }

    if (isInterested) {
      setUnsubscribedRequestIds([...unsubscribedRequestIds.filter((id) => id !== request.id)]);
    } else {
      setUnsubscribedRequestIds([...unsubscribedRequestIds, request.id]);
    }
  };

  useEffect(() => {
    (async () => {
      if (notifIdentifierParam) {
        const response = await fetchRequestForProposalsFromNotification(notifIdentifierParam);

        setIsShadowProfile(response.is_shadow_profile);
        setUserId(response.user_id);
        setSettings(
          response.notification_settings?.filter((setting) => setting.category === "marketing")
        );

        if (response.request_for_proposals.length) {
          setRequestForProposals(response.request_for_proposals);
          setUnsubscribedRequestIds(
            response.request_for_proposals
              .filter((request) => !request.is_interested)
              .map((request) => request.id)
          );
        } else {
          setStep("communication");
        }
      }
    })();
  }, [notifIdentifierParam]);

  if (step === "communication") {
    return (
      <BlankModal
        onClose={onClose}
        isOpen={isOpen}
        width={requestForProposals.length === 3 ? "1316px" : "960px"}
        height="100%"
      >
        <Container>
          <Top>
            <Logo src={haloLogo} alt="Halo home" />
            <HeadingContainer>
              <Subheader>Communication preferences</Subheader>
              <Header>Choose which Halo emails you&apos;d like to receive.</Header>
            </HeadingContainer>
            <OptionContainer>
              {settings.map((setting) => (
                <OptionRow key={setting.key} margin="4px 0 0">
                  <Switch
                    checked={setting.enabled}
                    onChange={() => {
                      track(SegmentEventName.Click, {
                        react_component: "RfpUnsubscribeModal",
                        ui_component: "Communication Preferences Toggle",
                        key: setting.key,
                        enabled: !setting.enabled,
                      });
                      handleToggle(setting.key, !setting.enabled);
                    }}
                    margin="4px 0 0"
                  />
                  <div>
                    <OptionHeading>{setting.title}</OptionHeading>
                    <OptionDescription>{setting.description}</OptionDescription>
                  </div>
                </OptionRow>
              ))}
            </OptionContainer>
          </Top>
          <Bottom>
            <StyledButton
              onClick={() => {
                track(SegmentEventName.Click, {
                  react_component: "RfpUnsubscribeModal",
                  ui_component: "Unsubscribe From All Button",
                });
                settings.forEach((setting) => handleToggle(setting.key, false));
              }}
            >
              Unsubscribe from all Halo communications
            </StyledButton>
          </Bottom>
        </Container>
      </BlankModal>
    );
  }

  return (
    <BlankModal
      onClose={onClose}
      isOpen={isOpen}
      width={requestForProposals.length === 3 ? "1316px" : "960px"}
      height="100%"
    >
      <Container>
        <Top>
          <Logo src={haloLogo} alt="Halo home" />
          <HeadingContainer>
            <Subheader>Opportunity recommendations</Subheader>
            <Header>Let us know which opportunities you&apos;d prefer not to hear about.</Header>
          </HeadingContainer>
          <CardsContainer columns={requestForProposals.length === 3 ? 3 : 2}>
            {requestForProposals.map((request) =>
              unsubscribedRequestIds.includes(request.id) ? (
                <UnsubscribedRfpCard
                  key={request.id}
                  request={request}
                  toggleUnsubscribeFromRfp={toggleUnsubscribeFromRfp}
                />
              ) : (
                <RfpCard
                  key={request.id}
                  request={request}
                  onClick={(e) => toggleUnsubscribeFromRfp(e, request, false)}
                  shouldShowUnsubscribeButton
                />
              )
            )}
          </CardsContainer>
        </Top>
        <Bottom>
          Not interested in hearing about future opportunities? Let us know your
          <StyledButton
            onClick={() => {
              if (isShadowProfile) {
                setStep("communication");
              } else {
                // Logged in users have account settings, so we take them there to control them.
                history.push(account_settings_path({ q: "notifications" }));
              }
              track(SegmentEventName.Click, {
                react_component: "RfpUnsubscribeModal",
                ui_component: "Communication Preferences Button",
              });
            }}
          >
            communication preferences.
          </StyledButton>
        </Bottom>
      </Container>
    </BlankModal>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 40px;

  padding: 48px;

  box-sizing: border-box;
`;

const Logo = styled.img`
  width: 65px;
  height: 32px;
`;

const HeadingContainer = styled.div`
  width: 100%;
`;

const Header = styled.div`
  color: ${COLORS.BLACK};
  ${FONTS.HEADING_3_REGULAR}
`;

const Subheader = styled.div`
  color: ${COLORS.NEUTRAL_500};
  ${FONTS.SEMIBOLD_1}
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, minmax(0, 1fr));
  grid-gap: 20px;

  ${WINDOW_DIMENSIONS.TABLET_LANDSCAPE_MEDIA_QUERY} {
    grid-template-columns: minmax(0, 1fr);
  }
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 24px 72px;

  border-top: 1px solid ${COLORS.NEUTRAL_300};

  ${FONTS.REGULAR_2}
  line-height: 14px;
  gap: 3px;
`;

const StyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;

  ${FONTS.SEMIBOLD_2}

  color: ${COLORS.HALO_BLUE};

  text-decoration: underline !important;
  text-decoration-color: ${COLORS.BLUE_600} !important;
  -webkit-text-decoration-color: ${COLORS.BLUE_600} !important;
  text-underline-offset: 4px !important;

  &:hover {
    color: ${COLORS.BLACK};
    text-decoration-color: ${COLORS.BLACK} !important;
    -webkit-text-decoration-color: ${COLORS.BLACK} !important;
  }

  &:focus {
    color: ${COLORS.BLACK};
    text-decoration: none;
  }
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 34px;
  width: 100%;
`;

const OptionRow = styled.div`
  display: flex;
  gap: 11px;
  align-items: flex-start;
  width: 100%;
`;

const OptionHeading = styled.div`
  ${FONTS.MEDIUM_1}
`;

const OptionDescription = styled.div`
  color: ${COLORS.NEUTRAL_500};
  ${FONTS.REGULAR_2}
`;

export default RfpUnsubscribeModal;
