import Icon, { IconName } from "@components/library/Icon/Icon";
import { COLORS, FONTS } from "@constants";
import { RfpBenefitType } from "@tsTypes/rfps";
import { FundingBenefit, NonMonetaryBenefit } from "@tsTypes/schema";
import styled from "styled-components";

interface Props {
  benefit: FundingBenefit | NonMonetaryBenefit;
}

const BenefitCard = ({ benefit: { benefit_type, details } }: Props) => {
  let label = "";
  let iconName: IconName;

  switch (benefit_type) {
    case RfpBenefitType.MARKET_ACCESS:
      label = "Market Access";
      iconName = "Public";
      break;
    case RfpBenefitType.EARLY_ACCESS:
      label = "Early Access";
      iconName = "History";
      break;
    case RfpBenefitType.EXPOSURE:
      label = "Exposure";
      iconName = "View";
      break;
    case RfpBenefitType.SPONSORED_RESEARCH:
      label = "Sponsored Research";
      iconName = "Funding";
      break;
    case RfpBenefitType.GIFT:
      label = "Gift (Unrestricted grant)";
      iconName = "Funding";
      break;
    case RfpBenefitType.INVESTMENT:
      label = "Investment";
      iconName = "Funding";
      break;
    case RfpBenefitType.PAYMENT:
      label = "Compensation";
      iconName = "Funding";
      break;
    case RfpBenefitType.EXPERTISE:
      label = "Expertise";
      iconName = "Expertise";
      break;
    case RfpBenefitType.NETWORKING:
      label = "Networking";
      iconName = "Networking";
      break;
    case RfpBenefitType.TOOLS_AND_TECHNOLOGY:
      label = "Tools and Technologies";
      iconName = "Technology";
      break;
    case RfpBenefitType.COMPOUNDS_AND_REAGENTS:
      label = "Compounds and Reagents";
      iconName = "Compounds";
      break;
    case RfpBenefitType.DATA:
      label = "Data";
      iconName = "Data";
      break;
    case RfpBenefitType.FACILITIES_AND_SERVICES:
      label = "Facilities and Services";
      iconName = "Organization";
      break;
    case RfpBenefitType.SERVICES_AGREEMENT:
      label = "Services Agreement";
      iconName = "Funding";
      break;
  }

  return (
    <Container>
      <Title>
        <Icon name={iconName} margin="0 6px 0 0" />
        {label}
      </Title>
      {details}
    </Container>
  );
};

export default BenefitCard;

const Container = styled.div`
  border: 1px solid ${COLORS.NEUTRAL_300};
  background-color: ${COLORS.WHITE};
  color: ${COLORS.BLACK};
  border-radius: 4px;
  padding: 16px;
  ${FONTS.REGULAR_2};
  word-break: break-word;
  white-space: pre-wrap;
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  ${FONTS.SEMIBOLD_1};
  margin-bottom: 16px;
  color: ${COLORS.BLACK};
`;
