import { useEffect, DependencyList } from "react";

declare global {
  interface Window {
    __hsWebInteractiveInstance: any;
  }
}

/**
 * This is a hook meant to workaround the limitation in the Hubspot tracking code on SPAs.
 * Hubspot expects pages to be static HTML so this reattaches the event listeners
 * whenever a component re-renders.
 *
 * As of Jan 17 2024, this seems to have the most details around this issue.
 * https://community.hubspot.com/t5/APIs-Integrations/Force-CTA-Scripts-to-Run-on-History-Change-for-single-page/m-p/879567#M69070
 */
export function useHubspotCTAs(dependencies: DependencyList): void {
  return useEffect(() => {
    window.__hsWebInteractiveInstance?.init();
  }, dependencies);
}
