import { Link } from "react-router-dom";

export const TOOLTIP_CONTENT = {
  RELEVANT: {
    NOT_SIGNED_IN: (
      <>
        <b>Mark as relevant</b>
        <div>Help Halo improve our request recommendations.</div>
      </>
    ),
    SIGNED_IN: (
      <>
        <b>Mark as relevant</b>
        <div>
          Help Halo improve our request recommendations. Requests you mark as relevant will also be
          added to your{" "}
          <Link to="/my_proposals" style={{ textDecoration: "underline", color: "white" }}>
            Saved requests
          </Link>
        </div>
      </>
    ),
  },
  NOT_RELEVANT: (
    <>
      <b>Mark as not relevant</b>
      <div>
        Remove this partnering request from your marketplace view and decline any updates about it.
      </div>
    </>
  ),
};
