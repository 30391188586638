import { Button } from "@components/library";
import { COLORS, FONTS } from "@constants";
import backgroundImage from "@images/backgrounds/error_page_background.png";
import { t, t_html } from "@utils/i18n";
import { ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

interface Props {
  quote: string | ReactElement;
  author: string;
  errorMessage: ReactElement;
  isErrorBoundary?: boolean;
}

const StatusPage = ({ quote, author, errorMessage, isErrorBoundary = false }: Props) => {
  const [didCatch, setDidCatch] = useState(false);

  const history = useHistory();

  // This is a workaround to simulate going back. React Router methods won't work if the app
  // caught, so we need to manually refresh after navigating back
  useEffect(() => {
    if (didCatch) window.location.reload();
  }, [history.location.pathname]);

  const handleCtaClick = () => {
    if (isErrorBoundary) setDidCatch(true);
    history.goBack();
  };

  return (
    <Container isErrorBoundary={isErrorBoundary}>
      <Quote>
        {quote}
        <Author>- {author}</Author>
      </Quote>
      <ErrorMessage>
        {errorMessage}
        <br />
        {t_html("pages.error_pages.shared.contact_html", {
          link: <a href="/contact">contact us</a>,
        })}
      </ErrorMessage>
      <Button type="button" onClick={handleCtaClick}>
        {t("pages.error_pages.shared.cta")}
      </Button>
    </Container>
  );
};

export default StatusPage;

const Container = styled.div`
  width: 100%;
  ${({ isErrorBoundary }) => {
    const navbarHeight = 84;
    const appContainerMarginBottom = 40;

    return isErrorBoundary
      ? `
        height: calc(100% - (${navbarHeight}px ));
        min-height: fit-content;
        padding-bottom: calc(100px + ${appContainerMarginBottom}px); 
      `
      : `
        height: calc(100% - (${navbarHeight}px - ${appContainerMarginBottom}px));
        padding-bottom: 100px; 
    `;
  }};
  background-image: url(${backgroundImage});
  background-size: max(100%, 1280px);
  background-position: bottom;
  background-repeat: no-repeat;
  border-top: 1px solid ${COLORS.NEUTRAL_200};
  text-align: center;
`;
const Quote = styled.div`
  max-width: 592px;
  margin: 80px auto 0;
  padding: 40px 0;
  border-bottom: 1px solid ${COLORS.NEUTRAL_250};
  font-size: 42px;
  line-height: 1.24;
  font-weight: 400;
`;
const Author = styled.div`
  margin-top: 8px;
  color: ${COLORS.NEUTRAL_500};
  ${FONTS.HEADING_3_REGULAR};
`;
const ErrorMessage = styled.div`
  width: 382px;
  margin: 40px auto 16px;
  ${FONTS.REGULAR_2};
  a {
    color: ${COLORS.BLACK};
    text-decoration: underline;
    &:hover {
      color: ${COLORS.HALO_BLUE};
    }
  }
`;
