import NavbarDropdownMenu from "@components/Navbar/NavbarDropdownMenu";
import { Button, GradientButton, Icon } from "@components/library";
import haloLogo from "@images/logos/halo_logo.svg";
import { RootState } from "@redux/store";
import { marketplacePath } from "@routes/utils";
import { UserRole } from "@tsTypes/users";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { COLORS, WINDOW_DIMENSIONS } from "src/constants";
import styled from "styled-components";
import LoginSignUpModal from "../LoginSignUpModal";
import LoggedOutNavbarLinks from "./LoggedOutNavbarLinks";
import MobileMenu from "./MobileMenu";
import ScientistNavbarLinks from "./ScientistNavbarLinks";
import SponsorNavbarLinks from "./SponsorNavbarLinks";
import UniversityAdminNavbarLinks from "./UniversityAdminNavbarLinks";
import { NAVBAR_BORDER_PATHS, NAVBAR_HIDDEN_PATHS } from "./navbarLists";

interface Props {
  shouldIgnoreHiddenPaths?: boolean;
}

const Navbar = ({ shouldIgnoreHiddenPaths }: Props) => {
  const [isSticky, setIsSticky] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);
  const isTablet = useSelector((state: RootState) => state.window.isTablet);

  const { pathname } = useLocation();

  const isUserLoggedOut = !currentUser.id;

  // Make nav bar sticky on main pages when user is logged out
  const isLandingPage = pathname === "/" || pathname.startsWith("/scientists");
  const isInMarketplaceRelatedPage =
    pathname.startsWith("/marketplace") ||
    pathname.startsWith("/research") ||
    pathname.startsWith("/company");

  const handleScroll = () => {
    if (isUserLoggedOut && window.scrollY >= 720 && (isLandingPage || isInMarketplaceRelatedPage)) {
      setIsSticky(true);
    } else if (window.scrollY === 0) {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [pathname]);

  const shouldHideNavbar =
    NAVBAR_HIDDEN_PATHS.some((path) => pathname !== "/" && pathname.startsWith(path)) &&
    !shouldIgnoreHiddenPaths;
  if (shouldHideNavbar) return null;

  const hasBorder = NAVBAR_BORDER_PATHS.some(
    (path) => pathname !== "/" && pathname.startsWith(path)
  );

  // Currently this is essentially only used for when a user is logged out.  Eventually this
  // should be consolidated with NavbarDropdownMenu.tsx, designs pending
  const showMobileMenuButton =
    pathname === "/" ||
    pathname.startsWith("/about") ||
    pathname.startsWith("/scientists") ||
    pathname.startsWith("/unsubscribe") ||
    (!currentUser.id && isInMarketplaceRelatedPage);

  return (
    <>
      <LoginSignUpModal />
      <MobileMenu isOpen={isMobileMenuOpen} onClose={() => setIsMobileMenuOpen(false)} />
      <Container isSticky={isSticky} hasBorder={hasBorder} data-testid="navbar-main">
        <Links>
          {isTablet && showMobileMenuButton && (
            <OpenMobileMenuButton type="button" onClick={() => setIsMobileMenuOpen(true)}>
              <Icon name="Burger" size="lg" />
            </OpenMobileMenuButton>
          )}
          {isUserLoggedOut ? (
            <a href="/">
              <Logo src={haloLogo} alt="Halo logo" />
            </a>
          ) : (
            <Link to={marketplacePath()}>
              <Logo src={haloLogo} alt="Halo logo" />
            </Link>
          )}
          {(() => {
            if (isTablet) return null;
            if (isUserLoggedOut) return <LoggedOutNavbarLinks />;
            if (currentUser.role === UserRole.SCIENTIST) return <ScientistNavbarLinks />;
            if (currentUser.role === UserRole.SPONSOR) return <SponsorNavbarLinks />;
            if (currentUser.role === UserRole.UNIVERSITY_ADMIN)
              return <UniversityAdminNavbarLinks />;
          })()}
          {isUserLoggedOut ? (
            <SignInButtons>
              {!isTablet && (
                <a href="/login">
                  <Button variant="ghost" margin="0 8px 0 0">
                    Sign in
                  </Button>
                </a>
              )}
              <a href="/get-started">
                <GradientButton size="md">Join now</GradientButton>
              </a>
            </SignInButtons>
          ) : (
            <NavbarDropdownMenu />
          )}
        </Links>
      </Container>
    </>
  );
};

export default Navbar;

const Container = styled.div`
  display: flex;
  align-items: center;
  position: ${({ isSticky }) => (isSticky ? "sticky" : "static")};
  top: ${({ isSticky }) => (isSticky ? 0 : "-84px")};
  height: 84px;
  width: 100%;
  padding: 0 ${WINDOW_DIMENSIONS.PAGE_SIDE_PADDING}px;
  background-color: ${COLORS.WHITE};
  border-bottom: 1px solid ${({ hasBorder }) => (hasBorder ? COLORS.NEUTRAL_200 : "transparent")};
  z-index: 100;
  transition: top 0.3s ease;
  ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
    padding: 0 24px;
  }
  ${WINDOW_DIMENSIONS.MOBILE_MEDIA_QUERY} {
    padding: 0 16px 0 10px;
  }
`;
const Links = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;
const Logo = styled.img`
  height: 32px;
  margin-right: 24px;
  margin-left: 8px;
`;
const SignInButtons = styled.div`
  display: flex;
  margin-left: auto;
  & a:hover {
    text-decoration: none;
  }
`;
const OpenMobileMenuButton = styled.button`
  border: none;
  background-color: transparent;
  &:focus {
    outline: none;
  }
`;
