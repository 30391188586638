/* eslint no-unused-vars: 0 */

/**
 * This file was automatically generated by ruby_to_typescript.rake.
 * Do not modify it by hand.
 */
export enum UserRequestPreferenceSource {
  Unknown = "Unknown",
  Email = "Email",
  PreferenceModal = "PreferenceModal",
  MarketplaceCard = "MarketplaceCard",
  FollowedNotification = "FollowedNotification",
  RequestPage = "RequestPage",
  Internal = "Internal",
}
