import { Icon } from "@components/library";
import MessageAttachmentGalleryModal from "@components/modals/MessageAttachmentGalleryModal/MessageAttachmentGalleryModal";
import { COLORS, FONTS, STYLES } from "@constants";
import emptyState from "@images/halo-empty-state.png";
import { checkUnread } from "@redux/actions/profileActions";
import { RootState } from "@redux/store";
import {
  InboxMessageThread,
  MessageAttachmentPartial,
  MessagePartial,
  MessageRecipient,
} from "@tsTypes/index";
import { User } from "@tsTypes/schema";
import { t } from "@utils/i18n";
import { proposalyPagePath } from "@utils/proposalyUtils";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getMessages } from "src/requests/messages";
import styled from "styled-components";
import MessageCompose from "./MessageCompose";
import MessageList from "./MessageList";

interface Props {
  currentThread?: InboxMessageThread | null;
  isInbox?: boolean;
  height?: string;
}

const Conversation = ({ currentThread, isInbox, height }: Props) => {
  const [messages, setMessages] = useState<MessagePartial[]>([]);
  const [messageError, setMessageError] = useState<AxiosError>();
  const [isLoadingMessages, setIsLoadingMessages] = useState(true);

  // Empty array closes the gallery, adding items opens it
  const [galleryAttachments, setGalleryAttachments] = useState<MessageAttachmentPartial[]>([]);
  const [focusedGalleryAttachmentIndex, setFocusedGalleryAttachmentIndex] = useState<number>();

  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);
  const messengerData = useSelector((state: RootState) => state.messenger.messengerData);
  const isMessengerMinimized = useSelector(
    (state: RootState) => state.messenger.isMessengerMinimized
  );

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (currentThread?.id && (!isMessengerMinimized || isInbox)) {
        try {
          const { message_thread } = await getMessages(currentThread.id);

          setMessages(message_thread.messages);
          setIsLoadingMessages(false);
          dispatch(checkUnread());
        } catch (e) {
          setMessageError(e);
          setIsLoadingMessages(false);
        }
      } else {
        setMessages([]);
        setIsLoadingMessages(false);
      }
    })();
  }, [currentThread, isMessengerMinimized]);

  // Opens gallery with provided attachments array, focused on provided attachment id
  const openGallery = (attachments: MessageAttachmentPartial[], attachmentId: number) => {
    setGalleryAttachments(attachments);
    const index = attachments.findIndex((attachment) => attachment.id === attachmentId);
    setFocusedGalleryAttachmentIndex(index);
  };

  const closeGallery = () => {
    setGalleryAttachments([]);
    setFocusedGalleryAttachmentIndex(undefined);
  };

  if (!currentThread?.id && isInbox) {
    return (
      <ZeroState>
        <ZeroStateImage src={emptyState} alt="pen and paper" />
      </ZeroState>
    );
  }

  const isThreadScientistUser =
    currentThread && currentUser.id === currentThread.scientist_user?.id;
  const isNonConfidentialProposalThread = currentThread && "company_members" in currentThread;
  const isDmThread = currentThread && "recipients" in currentThread;

  let recipient: MessageRecipient | Pick<User, "role"> | undefined;
  if (isInbox && currentThread) {
    if (!isThreadScientistUser) {
      recipient = currentThread.scientist_user;
    } else if (isNonConfidentialProposalThread) {
      recipient = currentThread.company_members[0];
    } else if (isDmThread) {
      recipient = currentThread.recipients.find(
        (_recipient) => _recipient.id !== currentThread.scientist_user.id
      );
    }
    // recipient is undefined when the current user is the associated scientist of a proposal thread and the
    // request is confidential
  } else {
    recipient = messengerData.recipient;
  }

  const hasProposalBanner = isInbox && currentThread && "proposal" in currentThread;

  return (
    <>
      <MessageAttachmentGalleryModal
        attachments={galleryAttachments}
        focusedAttachmentIndex={focusedGalleryAttachmentIndex}
        setFocusedAttachmentIndex={setFocusedGalleryAttachmentIndex}
        closeGallery={closeGallery}
      />
      <Container height={height}>
        <Messenger isInbox={isInbox}>
          <MessageListContainer hasBanner={hasProposalBanner}>
            {hasProposalBanner && (
              <ProposalBanner data-testid="proposal-banner">
                <ProposalLabel>
                  <Icon name="Proposal" size="xs" color={COLORS.NEUTRAL_500} margin="0 6px 0 0" />{" "}
                  Proposal
                </ProposalLabel>
                <ProposalTitle>
                  <div>{currentThread.proposal.title ?? t("common.misc.untitled_proposal")}</div>
                  <ViewProposal
                    to={proposalyPagePath({
                      proposaly_type: currentThread.proposaly_type!,
                      slug: currentThread.proposal.slug,
                    })}
                    target="_blank"
                  >
                    <Icon name="Open in a new tab" />
                  </ViewProposal>
                </ProposalTitle>
              </ProposalBanner>
            )}
            <MessageList
              messages={messages}
              messageError={messageError}
              currentThread={currentThread}
              isInbox={Boolean(isInbox)}
              recipient={recipient}
              isLoading={isLoadingMessages}
              openGallery={openGallery}
            />
          </MessageListContainer>
          <MessageCompose
            currentThread={currentThread}
            setMessages={setMessages}
            isInbox={isInbox}
            recipient={recipient}
            openGallery={openGallery}
          />
        </Messenger>
      </Container>
    </>
  );
};

export default Conversation;

const Container = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  height: ${(props) => props.height};
`;

const Messenger = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  padding: ${(props) => (props.isInbox ? "24px 20px" : "0 0 16px 0")};
`;

const MessageListContainer = styled.div`
  display: grid;
  grid-template-rows: ${({ hasBanner }) => (hasBanner ? "auto 1fr" : "1fr")};
  height: 100%;
  min-height: 0;
`;

const ProposalBanner = styled.div`
  min-width: 480px;
  margin-bottom: 24px;
`;

const ProposalLabel = styled.div`
  display: flex;
  align-items: center;
  ${FONTS.MEDIUM_3}
  color: ${COLORS.NEUTRAL_500};
`;

const ProposalTitle = styled.div`
  display: flex;
  justify-content: space-between;
  ${FONTS.MEDIUM_1};
  margin-top: 5px;

  div {
    ${STYLES.TWO_LINES}
    word-break: break-word;
  }
`;

const ViewProposal = styled(Link)`
  color: ${COLORS.HALO_BLUE};
  margin: 5px 25px 0 20px;
  ${FONTS.REGULAR_3};
`;

const ZeroState = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ZeroStateImage = styled.img`
  width: 250px;
`;
