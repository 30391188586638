import { CompanyAvatar, Icon } from "@components/library";
import Tooltip from "@components/library/Tooltips/Tooltip";
import { COLORS, FONTS, WINDOW_DIMENSIONS } from "@constants";
import useIsImageLoadable from "@hooks/useIsImageLoadable";
import { RootState } from "@redux/store";
import { CompanyPartial, ConfidentialCompany } from "@tsTypes/index";
import { Link } from "react-router-dom";
import styled from "styled-components";
// @ts-ignore
import { CompanyVerificationStatus } from "@tsTypes/companies.js.erb";
import { RequestForStartupsShowResponse } from "@tsTypes/request_for_startups/show";
import { RequestableType, RequestUnion } from "@tsTypes/requests";
import { useSelector } from "react-redux";

const HALO_COMPANY_ID = 2825;

interface Props {
  request: RequestUnion;
  asPreview?: boolean;
}
export default function Banner({ request, asPreview = false }: Props) {
  const isTablet = useSelector((state: RootState) => state.window.isTablet);

  const isCompanyPrivate = "private_alias" in request.company || request.confidential;
  const isRfs = request.requestable_type === RequestableType.REQUEST_FOR_STARTUP;
  const typedRfs = request.requestable as RequestForStartupsShowResponse["requestable"];
  const isHaloCompanyRequest = request.company.id === HALO_COMPANY_ID;

  const backgroundImageUrl =
    (isRfs && typedRfs.banner_image.url) || request.commercial_application?.banner_image.url;
  const isImageLoadable = useIsImageLoadable(backgroundImageUrl);

  return (
    <Container backgroundImageUrl={isImageLoadable && backgroundImageUrl} asPreview={asPreview}>
      <TopRow asPreview={asPreview}>
        <CompanyContainer
          as={
            (isCompanyPrivate || isHaloCompanyRequest || (isRfs && !typedRfs.company_linked)) &&
            "div"
          }
          to={
            !isCompanyPrivate &&
            !isHaloCompanyRequest &&
            ((isRfs && typedRfs.company_linked) || !isRfs) &&
            `/company/${(request.company as CompanyPartial).identifier}`
          }
          asPreview={asPreview}
          data-testid="company-name"
        >
          <CompanyAvatar
            imageUrl={"avatar" in request.company ? request.company.avatar.url : null}
            isPrivate={isCompanyPrivate}
            size={isTablet ? "sm" : "lg"}
          />
          {isCompanyPrivate
            ? (request.company as ConfidentialCompany).private_alias ?? "Private Company"
            : (request.company as CompanyPartial).company_name}
          {request.company.verification_status === CompanyVerificationStatus.VERIFIED && (
            <Icon name="Verified" size="sm" margin="0 0 0 -12px" color={COLORS.BLUE_LIGHT_650} />
          )}
          {isCompanyPrivate && (
            <Tooltip
              content={
                <>
                  This industry partner has opted to post this partnering request anonymously and
                  will disclose their identity to finalists.
                  <br />
                  <a
                    href="https://knowledge.halo.science/why-are-sponsors-private-for-some-rfps"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn more.
                  </a>
                </>
              }
              position="right"
              tooltipWidth="300px"
              shouldFlex={true}
              asPortal={true}
              arrowOffsetTop="45px"
            >
              <IconWrapper>
                <Icon name="Info Inverted" size="sm" color={COLORS.BLUE_450} />
              </IconWrapper>
            </Tooltip>
          )}
        </CompanyContainer>
        {isRfs && <ParentProgram asPreview={asPreview}>{typedRfs.parent_program}</ParentProgram>}
      </TopRow>
      <Title title={request.title} asPreview={asPreview} data-testid="rfp-title">
        {request.title}
      </Title>
      {request.commercial_application ? (
        <CommercialApplication to={`/application/${request.commercial_application.slug}`}>
          {request.commercial_application.name}
        </CommercialApplication>
      ) : (
        <div />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  min-height: 400px;
  padding: 32px;
  background: ${({ backgroundImageUrl }) =>
    backgroundImageUrl ? `url(${backgroundImageUrl})` : "#3B82F6"};
  background-size: cover;
  border-radius: 12px;
`;
const TopRow = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  ${({ asPreview }) =>
    !asPreview &&
    `
    ${WINDOW_DIMENSIONS.MOBILE_MEDIA_QUERY} {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }
  `}
`;
const CompanyContainer = styled(Link)`
  display: flex;
  align-items: center;
  gap: 16px;
  color: ${COLORS.WHITE};
  ${FONTS.HEADING_5_BOLD};
  &:hover {
    color: ${COLORS.WHITE};
    text-decoration: none;
  }
`;
const ParentProgram = styled.div`
  color: ${COLORS.WHITE};
  ${FONTS.HEADING_5_REGULAR};
  ${({ asPreview }) =>
    !asPreview &&
    `
    ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
      ${FONTS.REGULAR_2};
    }
  `}
`;
const Title = styled.div`
  max-width: 1048px;
  ${({ title }) => {
    if (title.length > 50) return FONTS.HEADING_JUMBO;
    return FONTS.HEADING_BANNER;
  }};
  margin-bottom: 48px;
  color: ${COLORS.WHITE};
  line-height: 1.2;
  letter-spacing: -0.03em;
  overflow-wrap: break-word;
  ${({ asPreview, title }) =>
    !asPreview &&
    `
      font-size: ${(() => {
        if (title.length > 67) return 52;
        return 64;
      })()}px;
      @media screen and (max-width: 1212px) {
        font-size: 52px;
      }
      @media screen and (max-width: 784px) {
        font-size: 42px;
      }
  `}
`;
const CommercialApplication = styled(Link)`
  display: flex;
  align-items: center;
  width: fit-content;
  height: 32px;
  padding: 0 12px 2px;
  background-color: ${COLORS.BLUE_750};
  border-radius: 100px;
  color: ${COLORS.WHITE};
  ${FONTS.SEMIBOLD_3};
  &:hover {
    color: ${COLORS.WHITE};
    background-color: ${COLORS.BLUE_600};
    text-decoration: none;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  margin-left: -12px;
`;
